import revive_payload_client_cF7JOs97Gk from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti_4mkxigzmujchqn2oshq3zzfibe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LpmVPCwmij from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti_4mkxigzmujchqn2oshq3zzfibe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CXjQCX6n79 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti_4mkxigzmujchqn2oshq3zzfibe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_R8pdULzJ0b from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.21.3_vite@5.4.6_@types+node@22.5.5_sass-embed_ek63mc3fmhnibiapv3dme2rhsq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_YdlNZEBwcM from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti_4mkxigzmujchqn2oshq3zzfibe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_8yZf2xQzbX from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti_4mkxigzmujchqn2oshq3zzfibe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_VWb9Im3Kel from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti_4mkxigzmujchqn2oshq3zzfibe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_yf2oFfNOLr from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti_4mkxigzmujchqn2oshq3zzfibe/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_lgYFJY278Y from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti_4mkxigzmujchqn2oshq3zzfibe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_3sRwIOYoFQ from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.3_typescript@5.6.2_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_PSnDr095cu from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti_4mkxigzmujchqn2oshq3zzfibe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/webapp/.nuxt/unocss.mjs";
import plugin_m2bISOr8RS from "/vercel/path0/node_modules/.pnpm/pinia-plugin-persistedstate@4.0.1_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.3_typescript@5_pfe2z73hdxpezveeobfvv3vh2e/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import titles_UQu9UlJ1rI from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_h3@1.12.0_magicast@0.3.5_rollup@4.21.3_vite@5.4.6_@types+node@22.5.5__xwrgvf6xguwj6goutosgx4py54/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_GpgD3nsjTw from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_h3@1.12.0_magicast@0.3.5_rollup@4.21.3_vite@5.4.6_@types+node@22.5.5__xwrgvf6xguwj6goutosgx4py54/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_ux0YwISbpn from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_magicast@0.3.5_rollup@4.21.3_vite@5.4.6_@types+node@22.5.5_sa_5cdtc7upv5szj5czuqloboegmm/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_0jwQkmWhuM from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_magicast@0.3.5_rollup@4.21.3_vite@5.4.6_@types+node@22.5.5_sa_5cdtc7upv5szj5czuqloboegmm/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import _00_setup_OesUu83x3t from "/vercel/path0/packages/ui/plugins/00.setup.ts";
export default [
  revive_payload_client_cF7JOs97Gk,
  unhead_LpmVPCwmij,
  router_CXjQCX6n79,
  _0_siteConfig_R8pdULzJ0b,
  payload_client_YdlNZEBwcM,
  navigation_repaint_client_8yZf2xQzbX,
  check_outdated_build_client_VWb9Im3Kel,
  view_transitions_client_yf2oFfNOLr,
  chunk_reload_client_lgYFJY278Y,
  plugin_vue3_3sRwIOYoFQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_PSnDr095cu,
  unocss_MzCDxu9LMj,
  plugin_m2bISOr8RS,
  titles_UQu9UlJ1rI,
  defaults_GpgD3nsjTw,
  siteConfig_ux0YwISbpn,
  inferSeoMetaPlugin_0jwQkmWhuM,
  _00_setup_OesUu83x3t
]